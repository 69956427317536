import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
import Carousel from '@mozaic-ds/freemarker/js/modules/_carousel.js';
import AddToCartForTopProducts from '../commons/addToCartForTopProduct.class';
const TOP_PRODUCTS_SWIPER = 'js-tertiary-top-products-swiper';
const TOP_PRODUCTS_CAROUSEL = 'js-zoom-on-carousel';
class TertiaryTopProducts extends lm.Composant {
  constructor(id) {
    super(id);
    const component = document.getElementById(id);
    const elemSliderTopProducts = component.querySelector(`.${TOP_PRODUCTS_SWIPER}`);
    const elemCarouselTopProducts = component.querySelector(`.${TOP_PRODUCTS_CAROUSEL}`);
    if (elemSliderTopProducts) {
      const swiperTopProductObject = {};
      swiperTopProductObject.instance = new SwiperSlider(`.${TOP_PRODUCTS_SWIPER}`);
      delete swiperTopProductObject.instance;
    }
    if (elemCarouselTopProducts) {
      const carouselTopProductsObject = {};
      carouselTopProductsObject.instance = new Carousel(elemCarouselTopProducts);
      delete carouselTopProductsObject.instance;
    }
    const addToCartForTopProductsInstance = new AddToCartForTopProducts(component, this.cartUpdate);
    addToCartForTopProductsInstance.init();
  }
}
lm.DOMReady(function () {
  const sectionElem = document.querySelectorAll('.component-tertiary-top-products');
  for (let i = 0; i < sectionElem.length; i++) {
    const idElem = sectionElem[i].getAttribute('id');
    if (idElem) {
      const tertiaryTopProducts = {};
      tertiaryTopProducts.instance = new TertiaryTopProducts(idElem);
      delete tertiaryTopProducts.instance;
    }
  }
});